<template>
  <section class="sf">
    <h1 class="title-h1"><span class="red">$SON </span>Token</h1>

    <section class="sf-box" ref="sf">
      <p>
        Project: SoccerN<br />
        Symbol: $SON<br />
        Contract(BSC):TBA<br />
      </p>
      <div id="echartsBox"></div>
    </section>
  </section>
</template>

<script>
import * as echarts from "echarts";
import { ref } from "vue";
export default {
  setup() {
    let myChart = ref("");
    return {
      myChart,
    };
  },
  mounted() {
    let time;
    window.addEventListener("resize", () => {
      clearTimeout(time);
      time = setTimeout(() => {
        this.get_echarts();
      }, 500);
    });
    const obser = new IntersectionObserver(([{ isIntersecting }]) => {
      if (isIntersecting) {
        setTimeout(() => {
          this.myChart = null;
          this.get_echarts();
        }, 500);
        obser.unobserve(this.$refs.sf);
      }
    });
    obser.observe(this.$refs.sf);
  },
  methods: {
    get_echarts() {
      if (this.myChart) {
        this.myChart.dispose();
      }
      let bdWid =
        window.clientWidth ||
        document.body.offsetWidth ||
        document.documentElement.clientWidth;
      let pie = {
        width: "65%",
        legLeft: "70%",
        legBot: "center",
        sercent: "center",
      };
      if (bdWid < 449) {
        pie.width = "100%";
        pie.legLeft = "center";
        pie.legBot = "12%";
        pie.sercent = ["center", "30%"];
      }
      this.myChart = echarts.init(document.getElementById("echartsBox"));
      var option;
      option = {
        title: {
          show: true,
          text: "Total supply:100 million",
          left: "center",
          bottom: "5%",
          color: "#FFF",
          textStyle: {
            color: "#FFF",
            fontSize: 14,
            fontWeight: "600",
          },
        },
        tooltip: {
          show: true,
          trigger: "item",
          confine: true,
          axisPointer: {
            type: "cross",
            snap: false,
          },
        },
        legend: {
          orient: "vertical",
          left: pie.legLeft,
          bottom: pie.legBot,
          textStyle: {
            color: "#FFF",
            fontSize: 13,
            fontWeight: "100",
          },
        },
        series: [
          {
            name: "Total supply:100million",
            type: "pie",
            radius: "50%",
            width: pie.width,
            center: pie.sercent,

            label: {
              show: true,
              color: "#FFF",
              fontSize: 13,
            },
            data: [
              { value: 20000000, name: "Presale 20%" },
              { value: 10000000, name: "Marketing 10%" },
              { value: 10000000, name: "Staking 10%" },
              { value: 20000000, name: "Predict 20%" },
              { value: 10000000, name: "Bet Mining 10%" },
              { value: 18000000, name: "Ecosystem 18%" },
              { value: 12000000, name: "Liquidity 12%" },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      this.myChart.setOption(option);
    },
  },
};
</script>

<style lang="scss" scoped>
.sf {
  width: 90%;
  margin: auto;
  padding-top: 6%;

  .sf-box {
    width: 100%;
    & > p {
      color: #fff;
      text-align: center;
      font-size: 1rem;
    }
    #echartsBox {
      width: 100%;
      height: 30rem;
    }
  }
}
</style>