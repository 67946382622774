<template>
  <section class="bet">
    <article>
      <!-- <div class="bet-text">
        <div class="text-inner">
          <h2 class="to-right" v-lazy remoClass="to-right">Bet to Earn<i class="i-blue"></i></h2>
          <p class="p-cont to-right" v-lazy remoClass="to-right">
            Predict the score and win tokens by guessing. <br>NFT holders can obtain mining profits by predicting scores.
          </p>
        </div>
      </div> -->
      <!-- <div class="bet-img">
        <i class="i-blue"></i>
        <img v-lazy class="to-left" remoClass="to-left" src="@\static\img\bet1.png" alt="" />
      </div> -->
    </article>
    <article>
      <div class="bet-img">
        <i class="i-green"></i>
        <img v-lazy  class="to-right" remoClass="to-right" src="@\static\img\bet2.png" alt="" />
      </div>
      <div class="bet-text">
        <div class="text-inner">
          <h2 v-lazy class="to-left" remoClass="to-left">Watch to Earn<i class="i-green"></i></h2>
          <p class="p-cont to-left" v-lazy remoClass="to-left">
            Watch sports games, celebrate and earn tokens.
          </p>
        </div>
      </div>
    </article>
    <article>
      <div class="bet-text">
        <div class="text-inner">
          <h2 class="to-right" v-lazy remoClass="to-right">Talk to Earn<i class="i-pink"></i></h2>
          <p class="p-cont to-right" v-lazy remoClass="to-right">
            In the interactive room, you can share your views and express your views. Every message is valuable and you can get benefits.
          </p>
        </div>
      </div>
      <div class="bet-img">
        <i class="i-pink"></i>
        <img v-lazy class="rotate" remoClass="rotate" src="@\static\img\bet3.png" alt="" />
      </div>
    </article>
    <article>
      <div class="bet-img">
        <i class="i-yellow"></i>
        <img v-lazy class="to-right" remoClass="to-right" src="@\static\img\bet4.png" alt="" />
      </div>
      <div class="bet-text">
        <div class="text-inner">
          <h2 class="to-right" v-lazy remoClass="to-right">Play to Earn<i class="i-yellow"></i></h2>
          <p class="p-cont to-left" v-lazy remoClass="to-left">
            Participate in sports games together to win token rewards.
          </p>
        </div>
      </div>
    </article>
    <article class="NFT last">
      <div class="bet-text">
        <div class="text-inner">
          <h2 class="to-bottom" v-lazy remoClass="to-bottom">SoccerN PASSPORT<i class="i-red"></i></h2>
          <p class="p-cont to-top" v-lazy remoClass="to-top">
            It is a kind of NFT with multiple utilities. The holders can participate in various activities such as  watching competition mining, chatting mining, etc.
          </p>
        </div>
      </div>
      <div class="bet-img">
        <i class="i-red"></i>
        <img v-lazy class="rotate" remoClass="rotate" src="@\static\img\bet5.png" alt="" />
      </div>
    </article>
  </section>
</template>

<script>
export default {
  setup() {},
};
</script>


<style lang="scss" scoped>
.bet {
  width: 90%;
  margin: auto;
  padding-top: 6%;
  .last {
    background: rgba(255, 255, 255, 0.1);
    overflow: hidden;
    border-radius: 1rem;
    margin-top: 6rem;
     .text-inner {
          .i-red {
            background: #E80026;
          }
     }
    .bet-img {
      padding: 1rem;
      i{opacity: 1;}
    }
  }
  article {
    display: flex;
    justify-content: space-around;
    margin-bottom: 4rem;
    & > div {
      width: 40%;
    }
    .bet-text {
      display: flex;
      align-items: center;

      .text-inner {
        h2 {
          color: #fff;
          width: 100%;
          font-weight: 500;
          i {
            width: 3rem;
            height: 0.6rem;
            display: block;
            margin-bottom: 2rem;
            border-radius: 1rem;
            margin-top: 0.3rem;
          }
          .i-blue {
            background: #70a2db;
          }
          .i-green {
            background: #33d684;
          }
          .i-pink {
            background: #eb2f96;
          }
          .i-yellow {
            background: #ff8f1f;
          }
        }
      }
    }
    .bet-img {
      position: relative;

      i {
        width: 200%;
        height: 200%;
        display: block;
        opacity: 0.5;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(1.6);
        z-index: -1;
        border-radius: 50%;
      }
      .i-blue {
        background: radial-gradient(
          circle,
          #70a2db,
          #040a21,
          transparent,
          transparent
        );
      }
      .i-green {
        background: radial-gradient(
          circle,
          #43cc88,
          #040a21,
          transparent,
          transparent
        );
      }
      .i-pink {
        background: radial-gradient(
          circle,
          #e492a2,
          #040a21,
          transparent,
          transparent
        );
      }
      .i-yellow {
        background: radial-gradient(
          circle,
          #e9b86e,
          #040a21,
          transparent,
          transparent
        );
      }
      .i-red {
        background: radial-gradient(circle, #ff1c41,#5c0d1a,transparent, transparent)
      }
    }
  }
}
</style>