<template>
  <section class="footer">
      <p>
        COPYRIGHT Snowy Moonlight Internet Technology LLC - ALL RIGHTS RESERVED
      </p>
      <ul>
          <li><a href="https://t.me/SoccerN_Channel"><img v-lazy src="@\static\img\l1.png" alt=""></a></li>
          <li><a href="https://www.youtube.com/channel/UCd9yR_hPoM850Ge88Cfn9fg"><img v-lazy src="@\static\img\l2.png" alt=""></a></li>
          <li><a href="https://twitter.com/SoccernOfficial"><img v-lazy src="@\static\img\l3.png" alt=""></a></li>
      </ul>
  </section>
</template>

<script>
export default {
  setup() {},
};
</script>

<style lang="scss" scoped>
.footer{
    width: 90%;
    margin: auto;
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 6%;
    padding-bottom: 1.5rem;
    p{
        color: #FFF;
        font-size: .7rem;
        span{
            font-size: .8rem;
        }
    }
    ul{
        overflow: hidden;
        li{
            float: right;
        }
        img{width: 2rem;margin-left: 1.5rem;}
  
    }
}

</style>
