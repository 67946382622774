<template>
  <section class="partner">
    <h1 class="title-h1">Partner</h1>
    <section>
        <ul>
            <li><img v-lazy class="to-top" remoClass="to-top" src="@\static\img\app4.png" alt="" /></li>
            <li><img v-lazy class="to-top" remoClass="to-top" src="@\static\img\app6.png" alt="" /></li>
            <li><img v-lazy class="to-top" remoClass="to-top" src="@\static\img\app3.png" alt="" /></li>
            <li><img v-lazy class="to-top" remoClass="to-top" src="@\static\img\app5.png" alt="" /></li>
            <li><img v-lazy class="to-top" remoClass="to-top" src="@\static\img\app2.png" alt="" /></li>
            <li><img v-lazy class="to-top" remoClass="to-top" src="@\static\img\app1.png" alt="" /></li>
            <li><img v-lazy class="to-top" remoClass="to-top" src="@\static\img\app13.png" alt="" /></li>
            <li><img v-lazy class="to-top" remoClass="to-top" src="@\static\img\app8.png" alt="" /></li>
            <li><img v-lazy class="to-top" remoClass="to-top" src="@\static\img\app9.png" alt="" /></li>
            <li><img v-lazy class="to-top" remoClass="to-top" src="@\static\img\app10.png" alt="" /></li>
            <li><img v-lazy class="to-top" remoClass="to-top" src="@\static\img\app11.png" alt="" /></li>
            <li><img v-lazy class="to-top" remoClass="to-top" src="@\static\img\app12.png" alt="" /></li>
        </ul>
    </section>
  </section>
</template>

<script>
export default {
  setup() {},
};
</script>

<style lang="scss" scoped>
.partner {
  width: 90%;
  margin: auto;
  padding-top: 6%;
  
  section{
      width: 100%;
      ul{
          width: 100%;
          display: flex;
          justify-content: space-between;
          flex-wrap:wrap;
          align-items: center;
          @for $i from 1 to 20{
            & > li:nth-child(#{$i}) img{
              transition-delay: $i * 0.15s;
            }
          }
          li{
              width: 22%;
              margin-bottom: 1.5rem;
              text-align: center;
              img{
                  width: 70%;
              }
          }
      }
  }
}
</style>