<template>
  <main id="main">
    <home></home>
    <bet></bet>
    <!-- <world></world> -->
    <watch></watch>
    <!-- <nice></nice> -->
    <sf></sf>
    <!-- <roadmap></roadmap> -->
    <partner></partner>
    <footerV></footerV>
  </main>
</template>

<script>
import home from "@/views/Home.vue";
import bet from "@/views/bet.vue";
// import world from "@/views/world.vue";
import watch from "@/views/watch.vue";
// import nice from "@/views/nice.vue";
import sf from "@/views/sf.vue";
// import roadmap from "@/views/roadmap.vue";
import partner from "@/views/partner.vue";
import footerV from "@/views/footer.vue";
export default {
  components: {
    home,
    bet,
    // world,
    watch,
    // nice,
    sf,
    // roadmap,
    partner,
    footerV,
  },
  setup() {},
  mounted() {
    
  },
  created(){
    // document.title = "SoccerN";
  }
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  transition: 1s;
}
li {
  list-style-type: none;
}
a {
  text-decoration: none;
  display: block;
  margin: auto;
  width: fit-content;
}
body {
  width: 100vw;
  background: #040a21;
  overflow-x: hidden;
}
img {
  width: 100%;
}
#app,
#main {
  width: 100%;
  overflow-x: hidden;
}
#main {
  & > section {
    margin-top: 6rem;
  }
}
//首屏动画公共样式
.to-left {
  transform: translateX(100%);
  opacity: 0;
}
.to-right {
  transform: translateX(-100%);
  opacity: 0;
}
.to-top {
  transform: translateY(100%);
  opacity: 0;
}
.to-bottom {
  transform: translateY(-100%);
  opacity: 0;
}
.rotate {
  transform: rotateY(90deg);
  opacity: 0;
}

// 公共样式
.title-h1 {
  text-align: center;
  color: #fff;
  font-size: 2.2rem;
  margin-bottom: 3rem;
  .red {
    color: #d62a81;
  }
}
.btn-red {
  background: #d62a81;
  padding: 0.3rem 1rem;
  border: none;
  border-radius: 1rem;
  color: #fff;
  text-align: center;
  width: 121px;
  white-space: nowrap;
  transition: 0.2s;
  &:hover {
    box-shadow: 0 0 5px #d686ae;
    background: #d62a81;
    cursor: pointer;
    color: #fff;
  }
}

.p-cont {
  font-size: 1rem;
  color: #fff;
}

@media only screen and (max-width: 449px) {
  #main {
    & > section {
      width: 95%;
    }
    .title-h1 {
      font-size: 2rem;
    }
    .btn-red {
      font-size: 1rem;
      width: 8rem;
    }

    .home {
      #video {
        transform: scale(4);
      }
      .navMenu {
        padding: 1rem 5%;
        ul {
          display: none;
        }
        .menu {
          display: block;
          width: 32px;
        }
      }
      .tel-menu {
        display: block;
      }
      .home-text {
        width: 100%;
        h3 {
          font-size: 2rem;
        }
        h1 {
          font-size: 3rem;
        }
        .a-btn {
          margin-top: 2.5rem;
          img {
            height: 2.5rem;
          }
        }
      }
    }

    .bet {
      article {
        margin-bottom: 7rem;
        & > div {
          width: 45%;
        }
        .bet-img {
          display: flex;
          align-items: center;
        }
      }
      .last {
        flex-wrap: wrap;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        & > div {
          width: 100%;
        }
        .bet-text {
          padding: 1rem;
          h2 {
            text-align: center;
            .i-red {
              margin-left: auto;
              margin-right: auto;
            }
          }
        }
      }
    }

    .world {
      .title-h1 {
        .red {
          display: block;
        }
      }
      .wod-box {
        article {
          width: 100%;
          padding: 0.5rem;
          margin-bottom: 3rem;
          h3 {
            margin-top: -1.4rem;
            background: #cb4242;
            width: 7rem;
            border-radius: 1rem;
            margin-left: auto;
            margin-right: auto;
            border: 1px solid #fff;
          }
        }
      }
    }

    .nice {
      .ni-box {
        article {
          width: 90%;
          border-radius: 1rem;
          a button {
            background: #d62a81;
            padding: 2px;
          }
        }
      }
    }

    .sf {
      .sf-box {
        #echartsBox {
          height: 35rem;
        }
      }
    }

    .roadmap {
      section {
        .inner {
          display: none;
        }
        .tel-inner {
          display: block;
        }
      }
    }

    .footer {
      flex-direction: column-reverse;
      ul,
      p {
        width: 100%;
        text-align: center;
      }
      ul {
        display: flex;
        justify-content: center;
        margin-bottom: 1rem;
        li {
          float: none;
          img {
            width: 1.5rem;
            margin-left: 0;
          }
        }
        & > li:nth-child(1n+2){
          margin-left: 1rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 450px) and (max-width: 699px) {
  #main {
    & > section {
      width: 90%;
    }

    .home {
      #video {
        transform: scale(4);
      }
      .navMenu {
        padding: 1rem 5%;
        ul {
          display: none;
        }
        .menu {
          display: block;
          width: 32px;
        }
      }
      .tel-menu {
        display: block;
      }
      .home-text {
        width: 70%;
        h3 {
          font-size: 2rem;
        }
        h1 {
          font-size: 3rem;
        }
        .a-btn {
          margin-top: 2.5rem;
          img {
            height: 2.5rem;
          }
        }
      }
    }

    .bet {
      .last {
        flex-wrap: wrap;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        & > div {
          width: 100%;
        }
        .bet-text {
          padding: 1rem;
          h2 {
            text-align: center;
            .i-red {
              margin-left: auto;
              margin-right: auto;
            }
          }
        }
      }
    }

    .world {
      .title-h1 {
        .red {
          display: block;
        }
      }
      .wod-box {
        article {
          width: 100%;
          padding: 0.5rem;
          margin-bottom: 3rem;
          h3 {
            margin-top: -1.4rem;
            background: #cb4242;
            width: 7rem;
            border-radius: 1rem;
            margin-left: auto;
            margin-right: auto;
            border: 1px solid #fff;
          }
        }
      }
    }

    .nice {
      .ni-box {
        article {
          width: 90%;
          border-radius: 1rem;
          a button {
            background: #d62a81;
            padding: 2px;
          }
        }
      }
    }

    .sf {
      #echartsBox {
        height: 20rem;
      }
    }

    .footer {
      flex-direction: column-reverse;
      ul,
      p {
        width: 100%;
        text-align: center;
      }
      ul {
        display: flex;
        justify-content: center;
        margin-bottom: 1rem;
      }
      li {
        float: none;
        img {
          width: 1.5rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 700px) and (max-width: 1199px) {
  #main {
    & > section {
      width: 85%;
    }
    .home {
      #video {
        transform: scale(2.5);
      }
      .navMenu {
        padding: 1rem 5%;
        ul {
          & > li:last-child {
            display: none;
          }
        }
      }
      .home-text {
        h3 {
          font-size: 2rem;
        }
        h1 {
          font-size: 3rem;
        }
        .a-btn {
          margin-top: 2.5rem;
          img {
            height: 2.5rem;
          }
        }
      }
    }
    .world {
      .wod-box {
        article {
          width: 48%;
          padding: 0.5rem;
          ul li {
            p {
              font-size: 0.7rem;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  html {
    font-size: 18px;
  }
  #main {
    & > section {
      width: 70%;
    }
    .home {
      #video {
        transform: scale(2);
      }
      .home-text {
        h3 {
          font-size: 2.5rem;
        }
        h1 {
          font-size: 4rem;
        }
        .a-btn {
          margin-top: 3rem;
          img {
            height: 3rem;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1600px) and (max-width: 2399px) {
  html {
    font-size: 20px;
  }
  #main {
    & > section {
      width: 60%;
    }
    .home {
      .home-box {
        h1 {
          font-size: 5rem;
        }
        h3 {
          font-size: 3rem;
          margin-bottom: 1rem;
        }
        .home-text {
          width: 40%;
        }
        .a-btn {
          margin-top: 3rem;
          img {
            height: 3rem;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 2400px) {
  html {
    font-size: 30px;
  }
  #main {
    & > section {
      width: 50%;
    }
  }
}
</style>
