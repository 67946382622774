export default {
    mounted(el) {
        let remoClass = el.getAttribute("remoClass") ? el.getAttribute("remoClass") : false;
        let src_ = el.src ? el.src : false;
        el.src = "";
        const observe = new IntersectionObserver(([{ isIntersecting }]) => {
            if (isIntersecting) {
                if (src_ && remoClass) {
                    el.src = src_;
                    el.classList.remove(remoClass)
                } else if (src_) {
                    el.src = src_;
                } else if (remoClass) {
                    el.classList.remove(remoClass)
                }
                observe.unobserve(el)
            }
        })
        observe.observe(el)

    }
}