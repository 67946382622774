/* eslint-disable */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import lazy from "./static/js/lazy"



const appv=createApp(App);
appv.directive("lazy",lazy);
appv.use(router).mount('#app');
