<template>
  <div class="home">
    <div class="navMenu">
      <img class="log" src="@\static\img\log.png" alt="" />
      <ul>
        <li>Home</li>
        <!-- <li>Buy Token</li> -->
        <!-- <li>Bet</li> -->
        <!-- <a href="https://whitepaper.soccern.xyz" target="_blank"><li>Whitepaper</li></a> -->
        <li><a href="https://app.soccern.online/buy">Buy Token</a> </li>
        <!-- <li @click="to_view('roadmap')">Roadmap</li> -->
        <!-- <li>
          <a href=""><button class="btn-red">Buy NFT</button></a>
        </li> -->
      </ul>
      <img class="menu" @click="swi_menu" src="@\static\img\menu.png" alt="" />
    </div>
    <div class="tel-menu" :class="!telMenu ? 'tranX' : ''">
      <div class="log">
       <img v-lazy src="@\static\img\log.png" alt="" />
       <img class="menu" @click="swi_menu" src="@\static\img\menu.png" alt="" />
      </div>
       <ul class="nav-ul">
          <li><p>Home</p></li>
          <!-- <li><p>Buy Token</p></li> -->
          <!-- <li><a href=""><p > Bet</p></a></li> -->
          <!-- <li><a href="https://whitepaper.soccern.xyz" target="_blank"><p @click="to_view('hold',true)">Whitepaper</p></a></li> -->
          <li><a href="https://app.soccern.online/buy" style="color:white">Buy Token</a></li>
          <!-- <li><p @click="to_view('roadmap',true)">Roadmap</p></li> -->
        </ul>
        <img class="close" @click="swi_menu" src="@\static\img\close.png" alt="">
    </div>
    <div class="home-box">
      <video
        id="video"
        src="@\static\mp4\promo.mp4"
        autoplay
        loop="loop"
        playsinline
        muted
        ref="video"
      >
        <source src="@\static\mp4\promo.mp4" type="video/mp4" />
      </video>
      <div class="home-text">
        <h3 v-lazy remoClass="to-left" class="to-left">Enjoy Rich Earning Opportunities In</h3>
        <h1 class="text-linear to-right" v-lazy remoClass="to-right" style="color:#d62a81">SoccerN</h1>
        <!-- <div class="a-btn">
          <img v-lazy remoClass="to-right" class="to-right" src="@\static\img\GooglePlay.png" alt="" />
          <img v-lazy remoClass="to-left" class="to-left" src="@\static\img\AppStore.png" alt="" />
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  setup() {
    let telMenu=ref(false);
    return {
      telMenu
    }
  },
  mounted() {
    
  },
  methods: {
     swi_menu(){
      this.telMenu=!this.telMenu;
    },
     to_view(e,tel=false) {
      if(tel){
        this.swi_menu()
      }
      document.getElementsByClassName(e)[0].scrollIntoView({
        behavior:"smooth",block:"center"
      })
    }
  },
};
</script>

<style lang="scss">
.home {
  width: 100%;
  height: 100vh;
  position: relative;
  .tel-nav-img,
  .tel-nav {
    display: none;
  }

  .navMenu {
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    padding: 1rem 5%;
    position: absolute;
    z-index: 2;
    align-items: center;
    .menu{
      display: none;
    }
    .log {
      display: inline-block;
      width: auto;
      height: 1.5rem;
    }
    & > ul {
      width: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      li {
        color: #ffffff;
        flex: 1;
        padding: 0.5rem 0.8rem;
        white-space: nowrap;
        font-size: 1rem;
        &:nth-child(-n+6):hover {
          background: -webkit-linear-gradient(45deg, #5eff00, #00d4ff, #f200ff);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          cursor: pointer;
        }
        a {
          color: #ffffff;
          font-size: 1.2em;
        }
      }
    }
  }
  .tranX{
    transform: translateX(-100%);
  }
  .tel-menu{
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,1);
    position: fixed;
    left: 0;
    top: 0;
    transition: .5s;
    display: none;
    z-index: 9;
    .log{
      width: 90%;
      padding: 1rem;
      overflow: hidden;
      margin: auto;
      img{
        height: 1.5rem;
        width: auto;
      }
      & > img:first-child{
        float: left;
      }
      & > img:last-child{
        float: right;
      }
    }
    .nav-ul{
      p{
        width: 100%;
        line-height: 3rem;
        color: #FFF;
        font-size: 1rem;
        text-align: center;
      }
    }
    .close{
      width: 32px;
      margin:1rem auto;
      display: block;
    }
  }
  .home-box {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    background: rgb(37 94 173 / 58%);

    video {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      transform: scale(1.2);
    }
    .home-text {
      text-align: center;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      h1 {
        font-size: 2.5rem;
        margin-bottom: 1rem;
        color: #fff;
      }
      h3 {
        color: #fff;
        font-weight: 500;
      }
      .a-btn {
        margin-top: 2rem;
        img {
          height: 2rem;
          width: auto;
        }
        & > img:last-child {
          margin-left: 1rem;
        }
      }
    }
  }
}
</style>
