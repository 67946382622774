<template>
  <section class="watch">
    <h1 class="title-h1">
      <span class="red">Watch </span>to Earn
    </h1>
    <div class="wa-box">
        <iframe width="100%" height="415" v-lazy src="https://www.youtube.com/embed/Q-09-poi_9s?start=8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
       <a href="#"><button class="btn-red">Connect</button></a> 
    </div>
  </section>
</template>

<script>
export default {
  setup() {},
};
</script>

<style lang="scss" scoped>
.watch{
    width: 90%;
    margin:auto;
    padding-top: 6%;
    .wa-box{
        width: 100%;
        iframe{
            background: #000;
            margin-bottom: 2rem;
        }
        .btn-red{
            margin: auto;
            display: block;
        }
    }
}

</style>